   console.log('DSi_basico.js'); 

   import axios from "axios";

    var url =  sessionStorage['url']

    var mobile = ismobile()


    function ismobile(){
        if (window.innerWidth < 600){
            mobile = true;
            return true
        }else{
            mobile = false;
            return false
        }
    };

    function maps(item){
        let link = 'https://www.google.com/maps/search/'+item
        window.open(link, '_blank');      
    };

    export function send_whats(cel,nome,ide, msg_enivar){

        console.log('mobile:');
        console.log(mobile);

        nome = nome.toLowerCase().trim();  //passo tudo pra minuscula

        cel = cel.replace(/[^\d]+/g,'');
        
        //  4499685172
        if (cel?.length > 10){
            cel = cel?.substring(0,2) + cel?.substring(3,20);//deixo de fora o 1º 9
        }
        

        nome = nome.split(" ");//separo as palavras
        nome = nome[0];//pego o primeiro nome        
        nome = nome.charAt(0).toUpperCase() + nome.slice(1);//passo 1 letra maiuscula
        // alert(nome);

        let quebra = '%0A'
        let msg = ''

        if (!msg_enivar){
            msg =  "Olá%20" + nome 

            if (ide){
                msg = ide+ quebra + "Olá%20" + nome 
            }
    
        }
        else{
            msg = msg_enivar
        }
        



        if (msg !==''){
            if (mobile){
            window.open("https://api.whatsapp.com/send?phone=55"+ cel +'&text='+ msg);
            }else{
            window.open("https://web.whatsapp.com/send?phone=55"+ cel +'&text='+ msg);
            }
            // https://wa.me/
        }

    };

    export  async function primeiro_nome(nome){

       //primeira maiuscula 
        nome = nome.toLowerCase().trim();  //passo tudo pra minuscula
        nome = nome.split(" ");//separo as palavras
        nome = nome[0];//pego o primeiro nome        
        nome = nome.charAt(0).toUpperCase() + nome.slice(1);//passo 1 letra maiuscula


        return nome
    };



    function moeda(event){
        // console.log(event.target.value);

        var v = event.target.value;//captura o valor do event

        v=v.replace(/\D/g,"") //permite digitar apenas números
        v=v.replace(/[0-9]{12}/,"inválido") //limita pra máximo 999.999.999,99
        v=v.replace(/(\d{1})(\d{8})$/,"$1.$2") //coloca ponto antes dos últimos 8 digitos
        v=v.replace(/(\d{1})(\d{5})$/,"$1.$2") //coloca ponto antes dos últimos 5 digitos
        v=v.replace(/(\d{1})(\d{1,2})$/,"$1,$2") //coloca virgula antes dos últimos 2 digitos

        // event.target.value = v;
        return v;  
    };



    function allUpper(event){
        let a = event;//captura o valor do event
        if ((a!=='')&&(a!=null)){  
            a = a.toUpperCase();
            return a;
        } 
        else{
            return ''
        }
    };



    function firstUpper(event){
        let a = event;//captura o valor do event

        if (a!=='' && a.length==1){  
          a = a.toUpperCase();
        } 
        return a
    };


    export function get_data_hora(tipo){

        let retorno = {}

        var now = new Date();
        let mes_atual = now.getFullYear() +'-'+ (("0" + (now.getMonth() + 1)).slice(-2))
        let mes_seguinte = now.getMonth()+1
        if (mes_seguinte==12){
            mes_seguinte = (now.getFullYear()+1) +'-01'
        }
        else{
            mes_seguinte = now.getFullYear() +'-'+ (("0" + (now.getMonth() + 2)).slice(-2))
        }

        retorno.mes_seguinte = mes_seguinte;


        let a = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
        retorno.hoje = a;


        a = retorno.hoje.slice(6, 10) +'-'+ retorno.hoje.slice(3, 5) +'-'+ retorno.hoje.slice(0, 2) ; //formato us yyyy-mm-dd
        retorno.today = a

        retorno.quinze_dias  = incDay(retorno.today, 15,'us')
        retorno.trinta_dias  = incDay(retorno.today, 30,'us')
        retorno.um_ano       = incDay(retorno.today, 365,'us')
        retorno.ano_atual    = now.getFullYear()
        retorno.ano_seguinte = now.getFullYear() + 1;

        // console.log('today:', retorno[tipo]);
        
        // ir acrescentando conforme demanda!
        return retorno[tipo]
        

    };

    export function define_data_hora(that){
        //passo o comando this la e recebo aqui o that
        // console.log('that:');
        // console.log(that);


        var now = new Date();
        let mes_atual = now.getFullYear() +'-'+ (("0" + (now.getMonth() + 1)).slice(-2))
        let mes_seguinte = now.getMonth()+1
        if (mes_seguinte==12){
            mes_seguinte = (now.getFullYear()+1) +'-01'
        }
        else{
            mes_seguinte = now.getFullYear() +'-'+ (("0" + (now.getMonth() + 2)).slice(-2))
        }

        try{
            that.mes_atual    =  mes_atual
            that.mes_seguinte = mes_seguinte
        }
        finally {}



        // console.log('mes_atual:', mes_atual);
        // console.log('mes_seguinte:', mes_seguinte);
        // let mes_atual = now.getMonth()+1

        

        //data longa 20 de abrthatil de 2022
        let b = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short',hour12: false, timeZone: 'America/Sao_Paulo' ,  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}); 
        that.hoje_week = b
        

        // console.log('update_values');
        let a = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
        // console.log('hoje = '+ a);
        that.hoje = a;

        //dia numerico do mes
        that.dia_hoje  = that.hoje.slice(0, 2)

        // a = new Date().toLocaleTimeString('pt-Br',{ timeStyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });
        a = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
        // console.log('hora = '+ a);
        that.hora = a;

        a = that.hoje + '_'+ that.hora;
        // console.log('data_hora = '+ a);
        that.data_hora = a;


        a = that.hoje.slice(6, 10) +'-'+ that.hoje.slice(3, 5) +'-'+ that.hoje.slice(0, 2) ; //formato us yyyy-mm-dd
        // console.log('today = '+ a);
        that.today = a;
        let today = a

        a = that.data_hora+'_' +sessionStorage['lg:USUARIO']
        // console.log('alterou = '+ a);
        that.quem_alterou = a;


        let yesterday = incDay(today, -1,'us')
        that.yesterday = yesterday

        let tomorrow = incDay(today, 1,'us')
        that.tomorrow = tomorrow
        // alert(yesterday)

    };

    export function get_quem_alterou(){
      let data = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  

      let hora = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
    
      let data_hora = data + '_'+ hora;
      
      let user = sessionStorage['lg:USUARIO']
    
      let r  = data_hora+'_'+user
      return r
    };

    



    function incDay(data,dias,pt){
        let a = new Date(data+' 00:00:00')//sempre que criar data definir horario
        a.setDate(a.getDate() + dias)//adiciono dias na data


        let r = new Date(a).toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });//formato dd/mm/yyyy 
        // alert('r:'+ a)
        // alert('r:'+ r)

        if (pt='us'){
            let today = r.slice(6, 10) +'-'+ r.slice(3, 5) +'-'+ r.slice(0, 2) ; //formato us yyyy-mm-dd
            return today
            
        }else if (pt='pt'){
            return r
        }
    };

    function incMonth(data,meses,pt){
        let a = new Date(data+' 00:00:00')//sempre que criar data definir horario
    
        //Adicionar meses 
        a = a.setMonth(a.getMonth()+meses);
        let r = new Date(a).toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });//formato dd/mm/yyyy 
        if (pt='us'){
            let today = r.slice(6, 10) +'-'+ r.slice(3, 5) +'-'+ r.slice(0, 2) ; //formato us yyyy-mm-dd
            return today
            
        }else if (pt='pt'){
            return r
        }
    };

    function strtofloat(vem){
        if (vem){
            vem = vem.replaceAll('.','');
            vem = vem.replaceAll(',','.');
        }

        return vem
    };

    export function floatTostring(vem){
        // alert(vem)
        let A = ''
        if (vem){
            A = vem.toLocaleString('pt-br',{ minimumFractionDigits: 2, maximumFractionDigits: 2 });
            // alert(A)

            // Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(total_juros);
            // A = A.replaceAll('.','');
            // A = A.replaceAll('.',',');
        }

        return A
    };

    export function floatTostring2(vem){
        // alert(vem)
        let A = ''
        if (vem){
            A = vem.toLocaleString('pt-br',{ minimumFractionDigits: 2, maximumFractionDigits: 2 });
            A = A.replaceAll('.',',');
        }
    
        return A
    };

    function getFormatDate(data){
        if (data?.length >2 ){
        let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
        return dia 
        }else{
        return ''
        }  
    };

    export function alerta(that,text,color, time){

        if (!time){
            time = 2000
        }
        
        // alert(text);
        that.snackbar = true;
        that.snack_text= text;
        that.snack_color = color;
        that.snack_timeout = time;

        if ( (text?.includes('ERROR:')) || (text?.includes('error'))){ 
            that.snack_color = 'red';
        }
    };

    export function  getFormatCurrency(v,string){
        
        let r = null
        if (string=='S'){
            r = v.toLocaleString('pt-br',{ minimumFractionDigits: 2, maximumFractionDigits: 2 });//funciona pra string
        }else{
            r = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(v); //funciona para numeros
        }

        return r
    };



    export function calcula_idade(data, campos){
        // console.log(data);

          if (data == '//'){ data = ''}

          var now = new Date();
          var today = new Date(now.getYear(),now.getMonth(),now.getDate());

          var yearNow = now.getYear();
          var monthNow = now.getMonth();
          var dateNow = now.getDate();
          var dob = new Date(data.substring(6,10),
              data.substring(3,5)-1,                    
              data.substring(0,2)                
              );

          var yearDob = dob.getYear();
          var monthDob = dob.getMonth();
          var dateDob = dob.getDate();
          var age = {};
          var yearAge = yearNow - yearDob;

          if (monthNow >= monthDob)
            var monthAge = monthNow - monthDob;
          else {
            yearAge--;
            var monthAge = 12 + monthNow -monthDob;
          }

          if (dateNow >= dateDob)
            var dateAge = dateNow - dateDob;
          else {
            monthAge--;
              var dateAge = 31 + dateNow - dateDob;

              if (monthAge < 0) {
                monthAge = 11;
                yearAge--;
              }
            }

          age = {
              years: yearAge,
              months: monthAge,
              days: dateAge
            };
        //   return age.years+' ano '+age.months+' mêses '+age.days+' dias'
        // return age;
        //   return age.years;


                //ADAPTACAO DSI
                if (data){
                  
                    let result = age

                    let r = ''
                    let mes = ' meses '
                    let ano = ' anos '

                    if (result.months == 1){
                        mes = ' mês '
                    }

                    if (result.years == 1){
                        ano = ' ano '
                    }
                    
                    //0 = oculta dias se nao for menor
                    if (campos == 0 ){

                        if (result.years < 1){
                            r = result.months+ mes +result.days+' dias'
                        }else {campos = 2}
                    }

                    if (campos == 1 ){
                        r = result.years+ ano
                    }else if (campos == 2 ){
                        r = result.years+ ano + result.months+mes
                    }else if (campos == 3 ){
                        r = result.years+ ano + result.months + mes + result.days+' dias' 
                    }

                    age['texto'] = r; //retorno um objeto 
                    return age
                    // return r
                }else{
                    return ''
                }
      };


      export function dif_datas(a,b){

        // var inicio     = new Date('2021-01-01 00:00:00')
        var AA     = new Date( a +' 00:00:00'); 
        var BB     = new Date( b +' 00:00:00'); 


        if (AA.getTime() === BB.getTime()){
            // console.log(AA + ' == '+ BB);
            // console.log('igual');
            return 0 //na validade
        }
        else if (AA.getTime() > BB.getTime()){
            // console.log('maior');
            // console.log((AA + ' >= '+ BB));
            return 1 //na validade
        }
        else{
            // console.log((AA + ' < '+ BB));
            // console.log('menor');
            return -1
        }
    };


    export async function diferenca_datas(d1, d2){

        const diffInMs   = new Date(d2) - new Date(d1)
        // alert(diffInDays)
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        // console.log(diffInDays) // 38

        
        return diffInDays
    };

    

      export async function dias_do_mes(){
        
        var objData = new Date(),
        numAno = objData.getFullYear(),
        numMes = objData.getMonth()+1,
        numDias = new Date(numAno, numMes, 0).getDate();

        let de  = numAno+'-'+numMes+'-01'
        let ate = numAno+'-'+numMes+'-'+numDias

        let r = {'de': de, 'ate':ate }

        // console.log(r);

    
        return  r
      };


      export async function  somenteNumeros(string){
        if (string){
          var numsStr = string.replace(/[^0-9]/g,'');
          return (numsStr);
        }
        else{
            return string
        }
      };

      export async function  gerar_token(entra){
        var s = Math.random().toString(16).substr(2)
        if (entra){
          s = entra +'_'+ s
        }

        s = s.toUpperCase()
      //   console.log('gerar token:', s);

        return (s);
      };


      export async function consulta_cep(cep){

        if (cep?.length>=9){

          let link = "https://viacep.com.br/ws/"+cep+"/json/"
          const res = await axios.get(link);
        //   console.log(res.data);

          let retorno = {

            'ENDERECO': res.data.logradouro.toUpperCase(),
            'BAIRRO'  : res.data.bairro.toUpperCase(),
            'CIDADE'  : res.data.localidade.toUpperCase(),
            'UF'      : res.data.uf.toUpperCase()
          }

          return  retorno
        }

      }; 

      export async function  removerCampos(that, nome_items, camposARemover) {
        for (let i = 0; i < camposARemover?.length; i++) {
          const index = that.items_editaveis.findIndex(x => x.value === camposARemover[i]);
          if (index >= 0) { 
            that[nome_items].splice(index, 1);
          }
        }
      };

      export async function copiarParaClipboard(texto) {

        if (!navigator.clipboard) {
          // Clipboard API não disponível
          console.error('Acesso à área de transferência não disponível.');
          return false;
        }

        try {
          await navigator.clipboard.writeText(texto);
          //   console.log('Texto copiado para a área de transferência!');
          return true
          
        } catch (err) {
         //   console.error('Falha ao copiar o texto: ', err);
          return false
        }
      };


      export function geraIDdataHora() {
        let date = new Date();
    
        // Extrair componentes da data e hora
        let year = date.getFullYear().toString().slice(-2); // Dois últimos dígitos do ano
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mês começa em 0, por isso adicionamos 1
        let day = date.getDate().toString().padStart(2, '0'); 
        let hours = date.getHours().toString().padStart(2, '0');
        let minutes = date.getMinutes().toString().padStart(2, '0');
        let seconds = date.getSeconds().toString().padStart(2, '0');
    
        // Formatar a data e hora conforme solicitado
        let formattedDate = `${year}${month}${day}_${hours}${minutes}${seconds}`;
    
        return formattedDate;
      };

        // Função para criar um arquivo e baixar
      export  async function downloadText(textContent, fileName) {

        const blob = new Blob([textContent], { type: 'text/plain;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };


      export async function processCsvFile(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onload = (e) => {
            const conteudo = e.target.result;
            console.log('conteudo (csv):', conteudo);
      
            // Processa o conteúdo do CSV
            const lines = conteudo.split('\n').map(line => line.trim());
            const headers = lines[0].split(';');
            const data = [];
      
            for (let i = 1; i < lines.length; i++) {
              if (lines[i]) { // Ignora linhas vazias
                const row = lines[i].split(';');
                const rowData = {};
                for (let j = 0; j < headers.length; j++) {
                  rowData[headers[j]] = row[j];
                }
                data.push(rowData);
              }
            }
      
            console.log('dados_importados (csv):', data);
            resolve(data); // Resolve a promessa com os dados processados
          };
      
          reader.onerror = (error) => {
            reject(error); // Rejeita a promessa em caso de erro
          };
      
          reader.readAsText(file);
        });
      }
      


      export async function processTxtFile(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onload = (e) => {
            const conteudo = e.target.result;
            console.log('conteudo (txt):', conteudo);
      
            try {
              const data = JSON.parse(conteudo);
              resolve(data); // Resolve a promessa com os dados processados
            } catch (error) {
              console.error('Erro ao parsear JSON:', error);
              reject(error); // Rejeita a promessa em caso de erro ao parsear JSON
            }
          };
      
          reader.onerror = (error) => {
            console.error('Erro ao ler o arquivo:', error);
            reject(error); // Rejeita a promessa em caso de erro na leitura do arquivo
          };
      
          reader.readAsText(file);
        });
      };


      export async function primeira_maiuscula(nome){

        nome = nome.toLowerCase().trim();  //passo tudo pra minuscula
        nome = nome.split(" ");//separo as palavras
        nome = nome[0];//pego o primeiro nome        
        nome = nome.charAt(0).toUpperCase() + nome.slice(1);//passo 1 letra maiuscula

        return nome
      };

      export async function total_da_lista(that, item_name, items_somatorias, campo_valor,  campo_titulo , evitar_string){

        // percorro o array de objeto somando os valores pelo nome do campo..
        const resultado = {};
        // that.items_somatorias = []; // Limpa o array de somatórias
        that[items_somatorias] = []; // Limpa o array de somatórias
  
        if (campo_titulo) {
            let total = 0;
            let saldo = 0;
  
            that[item_name].forEach((item) => {
                let   campo = item[campo_titulo] || campo_titulo; // Se o campo não existir, usa o valor de campo_titulo
                // let   valor = parseFloat(strtofloat(item[campo_valor]));
                let   valor = parseFloat((item[campo_valor]));
  
                // console.log('campo_titulo:', campo_titulo);
                
                if (campo_titulo == 'TIPO'){
                   if (campo == 'S'){
                      campo = 'SAIDA'
                      saldo -= parseFloat(valor);
                   }
                   else if (campo == 'E'){
                      campo = 'ENTRADA'
                      saldo += parseFloat(valor); 
                   }
                }
                // console.log('valor:', valor);
                // console.log('saldoo:', saldo);

  
                if (resultado[campo]) {
                    resultado[campo] += parseFloat(valor);
                } else {
                    resultado[campo] = parseFloat(valor);
                }
  
                total += valor;
            });
  
            resultado['TOTAL'] = parseFloat(total);
            resultado['SALDO'] = parseFloat(saldo);
            // console.log('saldo:', saldo);
            // console.log('resultado:', resultado);
            
            
  
            // Criar um novo array de objetos com os valores agrupados e a quantidade por campo
            const novoArrayDeObjetos = Object.keys(resultado).map((campo) => {
                return {
                    CAMPO: campo,
                    VALOR: resultado[campo].toFixed(2),
                    'QT': (campo !== 'TOTAL') ? that[item_name].filter(item => item[campo_titulo] === campo).length : that[item_name].length
                    // 'QT': campo !== 'TOTAL' ? this[item_name].filter(item => item[campo_titulo] === campo).length : ''
                };
            });
  
  
            // console.log(novoArrayDeObjetos);
            if (evitar_string){//faço um filtro tirando chaves que contenham alguma string que nao quero mostrar
              that[items_somatorias] = novoArrayDeObjetos.filter(elemento => !elemento.CAMPO.includes(evitar_string));
            }else{
              that[items_somatorias] = novoArrayDeObjetos;
            }
  
            
        }   

        return true
      };
      
    

    




export default {
    ismobile,
    maps,
    send_whats,
    moeda,
    allUpper,
    firstUpper,
    define_data_hora,
    incDay,
    incMonth,
    strtofloat,
    floatTostring,
    floatTostring2,
    getFormatDate,
    alerta,
    getFormatCurrency,
    calcula_idade,
    dif_datas,
    diferenca_datas,
    dias_do_mes,
    gerar_token,
    somenteNumeros,
    geraIDdataHora,
    get_quem_alterou,
    primeira_maiuscula

};


