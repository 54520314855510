
// alert('dsi rotinas');
console.log('DSi rotinas');

// url_app(); //tem que ser chamado antes de tudo para nao dar erro de conexao

async function url_app(){

  console.log('DSi rotinas url app()');
  
  var url       = window.location.href//pega url e faz o tratamento
  var url_base  = 'http://'+location.host
  // console.log('url: '+ url);


  if (url.includes(':80')){//desenvolvimento
     url = '/api/'
     url_base = url
     console.log('desenvolvimento:'+ url)
  }else{ //producao
      
      //  url =  url.replace('/#/','/')
      if (url.includes('#')){
        url = url.substr(0,url.indexOf('#'));
      }
      else if (url.includes('#')){
        url =  url.replace('?','')//quando da Enter nos formularios...aparece esse ? no url
      }
      
     console.log('producao:'+ url)
  }
  
  console.log('url_base:'+url_base);
  
  sessionStorage['url']      = url 
  sessionStorage['url_base'] = url_base 
  

  
};

export async function meu_url(){

  // aqui so retorno o url para logar .. nao salvo no localstorage 
  // console.log('DSi_rotinas.js meu_url()');
  

  var url       = window.location.href//pega url e faz o tratamento
  // var url_base  = 'http://'+location.host
  if (url.includes(':80')){//desenvolvimento
     url = '/api/'
    //  console.log('desenvolvimento:'+ url)
  }
  else{ //producao
      url = url.substr(0,url.indexOf('#'));
      url =  url.replace('?','')//quando da Enter nos formularios...aparece esse ? no url
    //  console.log('producao:'+ url)
  }
  
   return  url
  // sessionStorage['url']      = url 
};

function alertou(){
  console.log('alertou');
  alert('execute')
};


export  function  return_key(){

  //pego o mes e o dia multiplico e chego num valor.. sera analisado   
   let dia = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' }); 
   dia =  dia.slice(3, 5) + dia.slice(0, 2) ; //mmdd
   let a = dia.substring(0,2)
   let b = dia.substring(4,2)
   let c = (a * 3 * b)

  //  alert(c)
   return c
};



export async function logado(that){

  
  // return true

    let key_session  =  sessionStorage.getItem('refresh_size') 
    let logado       =  sessionStorage.getItem('buffer')
    let expira       =  new Date(sessionStorage['expira'])//ver se expirou o login
    let key          =  return_key().toString() //passo pra string para comparar
    

    if (key_session == undefined){
        key_session = ''
    }
    key_session  =  key_session.toString()//passo pra string para comparar


    let agora = new Date();
    // alert(agora)
    // alert(expira)
    

    await SEM_F12()
    
    if ( (logado !=='S') || (key !== key_session) || (expira < agora)) {

      sessionStorage.clear()//limpa session

  
      localStorage['reload_page'] = 'S' //mando dar um reload no login para pegar o url

      that.$router.push('/')//redireciona para a pagina login

      return false
    }

    //chamo o ler_session do app.vue para atualizar o nome do usuario
    that.$emit('executar2', 'ler_session')  

    return true
  
};


export async function SEM_F12(){

  // USA VEZ EXECUTADO ELE FICA MONITORANDO.. CHAMO ELE NO CREATE DO VUE E DEPOIS NO LOGADO TB.. 
  // alert('SEM_F12')
  //DESABILITA F12

  let bloqueia = true

  // EM DESENVOLVIMENTO NAO BLOQUEIA
  if (sessionStorage['url']!=='/api/'){

    // USER A NAO BLOQUEIA
     if (sessionStorage['lg:USUARIO']){
        bloqueia = sessionStorage['lg:USUARIO'] !='A'
     }
     
    //  alert('bloqueia: '+bloqueia)
  }
  else {
     bloqueia = false
  }



  // bloqueia = true //testar


  if (bloqueia){
    


      var e = new Error();
      var agora_sim = false
    



      // CHROME
      Object.defineProperty(e, 'stack', {
        get() {
            if (agora_sim) {
                // alert('Devtools aberto');
            }
        }
      });



      // FIREFOX
      Object.defineProperties(e, {
        toString: {value() {e.stack.includes('toString') && alert('Safari devtools')}},
        message: {get() {

          agora_sim = true

          // console.log('e.stack:', e.stack);
          // alert('ABERTOOO')
          //limpo a sessao SAIR limpa session
          
          sessionStorage.clear();
          window.location.replace("https://www.google.com") //impede retornar pelo historico        

          // console.clear()

      

        }},
      })

      console.log(e);


    

      //  METODO 3 ANTIGO, PAROU DE FUNCIONAR NO CHROME EM 06/2024
      // console.log(Object.defineProperties(new Error, {
      //    toString: {value() {(new Error).stack.includes('toString@') && alert('Safari devtools')}},
      //    message: {get() {

      //         //limpo a sessao SAIR limpa session
      //         sessionStorage.clear();

      //         window.location.replace("https://www.google.com") //impede retornar pelo historico        

      //    }},
      // }))
      

  }
};



 export function cripto(entra){
    let A = entra
    

    if (A == undefined){
       A='.|.'
    }

    // console.log('A:'+A);

    let simbolos = [
      'ABCDEFGHIJLMNOPQRSTUVXZYWK ~!@#$%^&*()\/:.[]{}', //0
      'æÆôÂƒòçêùÿøúèñÀ©Ñªº¿Ø×£û®¬¼ë5ÜïÙýÄÅÉö»Á?|,<>"`', //1
      'abcdefghijlmnopqrstuvxzywk1234567890',           //2
      '+á¾¶²îÕµå½íóÇ³üÏé·¹§÷âÎ-ÌàÓß¸°¨þìä¡«'            //3
    ]

    
    //INVERTE STRING
    let AA = ''
    // alert(A.length)
    for (let i=0; i< A.length; i++){
      AA += A[(A.length-1) - i]
    }
    // console.log('AA:'+AA);
    A = AA.trim()

    let T = A.length
    let R =' '
    for (let x = 0; x < T; x++){

          if (simbolos[0].includes( A[x] )){
            let po = (simbolos[0].indexOf(A[x]))
              // console.log('po: '+ po +' achou :|'+A[x]+'| = |'+simbolos[1][po]+'|');
              R += simbolos[1][po] 
          }
          
          else if (simbolos[1].includes( A[x] )){
            let po = (simbolos[1].indexOf(A[x]))
              // console.log('po: '+ po +' achou :|'+A[x]+'| = |'+simbolos[0][po]+'|');
              R += simbolos[0][po] 
          }
          
          else if (simbolos[2].includes( A[x] )){
            let po = (simbolos[2].indexOf(A[x]))
              // console.log('po: '+ po +' achou :|'+A[x]+'| = |'+simbolos[3][po]+'|');
              R += simbolos[3][po] 
          }
          
          else if (simbolos[3].includes( A[x] )){
            let po = (simbolos[3].indexOf(A[x]))
              // console.log('po: '+ po +' achou :|'+A[x]+'| = |'+simbolos[2][po]+'|');
              R += simbolos[2][po] 
          }
        // }
    }
    
    // console.log('A:'+A);
    // console.log('R:'+R);
    return R

  };


  export function permissoes(campo){
     
     //busco dados da session
    
     let r = ''
     
     if (campo == 'superUser'){
        return   sessionStorage.getItem('r0s9i3khepus_is') =='S' 
        // r =   sessionStorage.getItem('r0s9i3khepus_is')=='S' 
        // console.log('permissoes: '+campo +':'+r);
     }
  };


  

  
// export default exports;
export default {
    cripto,
    SEM_F12,
    url_app,
    meu_url,
    logado,
    return_key,
    permissoes,
    alertou
};